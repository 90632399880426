import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay"

const VideoButton = props => {
  const query = useStaticQuery(graphql`
    query HeaderQuery {
      allTranslationsJson {
        nodes {
          watchVideo {
            en
            es
          }
        }
      }
    }
  `)

  const btnClass = classNames(props.buttonClass, {
    "standard-button": !(props.iconOnly || props.textOnly),
    "button-video": true,
    "video-button": props.overVideo === false ? false : true,
    "icon-only": props.iconOnly,
    "z-1": props.overVideo,
    "sizzle-button-under": props.sizzleButton,
    minimal: props.minimal ? props.minimal : false,
    "btn-left": props.textAlign === "left",
    "btn-right": props.textAlign === "right",
    "btn-center": props.textAlign === "center"
  })

  const { language } = props

  const translations = query.allTranslationsJson.nodes[0]

  let buttonText

  if (props.buttonText) {
    buttonText = props.buttonText
  } else {
    buttonText = translations.watchVideo[language]
  } 

  if (props.textOnly) {
    return (
      <a
        role="button"
        aria-label="watch video"
        className={btnClass}
        onClick={props.toggleTheater}>
        {buttonText}
      </a>
    )
  }

  return (
    <a
      role="button"
      aria-label="watch video"
      className={btnClass}
      onClick={props.toggleTheater}>
      <FontAwesomeIcon className="button-icon" icon={faPlay} />
      {!props.iconOnly && buttonText}
    </a>
  )
}

VideoButton.defaultProps = {
  language: "en"
}

VideoButton.propTypes = {
  minimal: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  overVideo: PropTypes.bool,
  toggleTheater: PropTypes.func
}

export default VideoButton
