import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"

function PSATC() {
  return (
    <Layout language="en">
      <SEO
        robots="noindex"
        title="NUVOLUM PROFESSIONAL SERVICES AGREEMENT
TERMS AND CONDITIONS"
        description="NUVOLUM PROFESSIONAL SERVICES AGREEMENT
TERMS AND CONDITIONS"
      />

      <Section className="joshua-tree-content privacy-policy-page">
        <Container>
          <div className="has-text-centered mb-2">
            <p className="bold">
              NUVOLUM PROFESSIONAL SERVICES AGREEMENT TERMS AND CONDITIONS
            </p>
            <p className="mb-0">(Hereinafter referred to as “Agreement”)</p>
            <p className="mt-0">v1.22.25</p>
          </div>

          <p className="bold">
            THIS PROFESSIONAL SERVICES AGREEMENT TERMS & CONDITIONS
            (“Agreement”) GOVERNS CLIENTS’S ACQUISITION AND UTILIZATION OF
            NUVOLUM (“Nuvolum”) SERVICES. CAPITALIZED TERMS HAVE THE DEFINITIONS
            SET FORTH HEREIN.
          </p>
          <p className="bold">
            BY ACCEPTING THIS AGREEMENT, BY (1) CLICKING A BOX INDICATING
            ACCEPTANCE, (2) EXECUTING AN ORDER FORM THAT REFERENCES THIS
            AGREEMENT, OR (3) USING NUVOLUM SERVICES, CLIENT AGREES TO THE TERMS
            OF THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS
            ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH
            INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH
            ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH
            CASE THE TERM “CLIENT” SHALL REFER TO SUCH ENTITY AND ITS
            AFFILIATES. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE
            SUCH AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS AND CONDITIONS,
            SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE
            SERVICES.
          </p>
          <p className="bold">
            Nuvolum’s direct competitors are prohibited from accessing the
            Services, except with Nuvolum’s prior written consent.
          </p>
          <p className="bold">
            This Agreement was last updated on January 22, 2025. It is effective
            between Client and Nuvolum as of the date of Client’s accepting this
            Agreement.
          </p>
          <p className="bold">1.0 SCOPE OF WORK</p>
          <p>
            Monthly Marketing which may include all or any combination of the
            activities listed in the attached Services Order Form, including any
            special programs and/or PR-Social Campaigns, along with any
            additional Addendums and/or Agreements executed between the parties
            thereafter, are hereby incorporated by reference into this
            Agreement.{" "}
          </p>
          <p>
            It is understood between the parties that Nuvolum is partnering with
            Client to improve the overall patient experience, and provide both
            online and offline marketing and awareness campaigns/efforts to
            improve and grow Client’s practice. As such, it is understood that
            Client will utilize the services, products, and technology stacks
            offered by Nuvolum to do so, and no other products of similar
            functionality or capability. Should Client wish to evaluate products
            or services that Nuvolum does not offer, but that will affect,
            and/or potentially interfere with, the services and products of
            Nuvolum, Client will first engage with Nuvolum regarding said
            evaluation. Nuvolum will determine whether it will be best to lead
            the evaluation of such product or service on behalf of Client, or
            partner with Client in the investigation thereof. It is further
            understood that Nuvolum may choose to add the service or product
            functionality being evaluated to its offerings so that there is a
            seamless and holistic integration offering in the best interests of
            Client.
          </p>

          <p className="bold">2.0 CHANGES</p>
          <p>
            If Client wishes to obtain additional services not covered by the
            Scope of Work defined in Services Order Form, Client shall submit a
            written description of the request for additional services to
            Nuvolum. Nuvolum shall provide a cost estimate in writing for
            performing the additional services. The parties shall, prior to the
            commencement of work on any additional services, execute an
            amendment to this Agreement.
          </p>

          <p className="bold">3.0 PERIOD OF PERFORMANCE</p>
          <p>
            The performance of services to be rendered under this Agreement are
            scheduled to commence upon execution of this Agreement by both
            parties, and continue for the number of months stated in Services
            Order Form thereafter (“Term”) unless otherwise terminated pursuant
            to the terms and conditions of this Agreement. Services shall only
            commence upon execution and receipt of this Agreement and payment of
            the agreed upon fees. At the end of the Term, this Agreement will
            automatically renew for 24 months, and then automatically for
            24-month terms thereafter. If Client wishes to terminate this
            agreement prior to renewal, written notice must be provided to
            Nuvolum a minimum of 90 days prior to renewal. The terms and
            conditions of this Agreement will remain in effect upon each renewal
            unless otherwise modified in writing signed by both parties or until
            this Agreement is terminated as provided for herein.{" "}
          </p>

          <p>
            The objectives and deliverables set out in Services Order Form are
            estimated and/or proposed deliverables and are subject to Client’s
            ability to provide certain information, resources, etc. to Nuvolum.
            Nuvolum will make its best efforts to deliver on said objectives but
            does not guarantee the outcome of said activities, due to factors
            beyond its control.
          </p>

          <p className="bold">4.0 PLACE OF PERFORMANCE</p>
          <p>
            All services under this Agreement shall be performed at one or more
            of Nuvolum’s offices or at such other locations as determined by
            Nuvolum.
          </p>

          <p className="bold">5.0 PRICING & BILLING</p>
          <p>
            Nuvolum operates on a fixed monthly retainer based upon the labor
            required to carry out the Services outlined in Services Order Form.
            The Marketing Management Services monthly investments/payments are
            outlined therein for the initial Term. In each year thereafter of
            any renewal Term, the investment will automatically increase each
            year by five percent (5%) over the prior year. It is understood by
            the parties that the work performed will not always be evenly spread
            between the months covered by this Agreement.
          </p>
          <p>
            Expenses incurred for the execution of any part of this proposal
            shall be billed separately according to the terms of this Agreement
            (eg. Printing; third party services such as press release
            distribution; advertising expenses such as postage; SEM fees such
            as: pay-per-click budgets, boosting budgets for social media and/or
            video advertising; eTools; travel expenses [airfare, meals, lodging,
            car rental/service, shipping, etc.] of Nuvolum personnel & equipment
            necessary for execution of plan; on-site cost(s) for hosting any
            event(s) [eg. food & beverage, room/equipment rental, giveaways,
            etc.] for any PR Social Campaigns included under this or any
            subsequent Agreement, etc.). Client authorizes Nuvolum to bill the
            Client’s card on file for any expenses that do not exceed $400 (four
            hundred dollars) in any given month. Expenses that exceed $400 in a
            month must be approved by Client.
          </p>
          <p>
            Upon commencement of services under this Agreement, Nuvolum and
            Client will agree upon dates for the execution of said services.
            Those dates represent Nuvolum’s inventory of staff time allocated to
            Client. Should Client choose to reschedule services that are already
            booked and agreed upon, or Client fail to deliver and/or meet said
            schedule with respect to Client deliverables (resulting in Nuvolum
            losing said inventory allocated to Client), Client will be subject
            to a rescheduling fee. Rescheduling fees will vary based upon
            service(s) being delayed and/or rescheduled, but by way of example,
            the rescheduling of a booked, on-site video shoot is fifteen
            thousand dollars.
          </p>

          <p className="bold">6.0 PAYMENT</p>
          <p>
            Nuvolum shall process the Client’s fixed monthly fee, along with any
            additional added on services agreed upon by Client, via ACH
            processing. The monthly fixed fee will be billed on the date of
            execution of this Agreement, and then monthly thereafter on either
            the 10th or the 25th of each month, whichever is closer to the
            Agreement Date.{" "}
          </p>
          <p>
            Additional expenses as outlined in section 5.0 will be billed as
            follows: 1) Nuvolum will make its best effort to have pre-approved
            expenses billed directly to the client whenever possible (via a
            credit card kept on file for such purposes). 2) If Nuvolum must
            invoice Client for expenses, an administrative mark-up of 10% shall
            be applied. Invoice is due upon receipt, prior to expense being
            incurred by Nuvolum. Expense invoices not paid by the due date will
            incur a late fee of 10% per month.
          </p>
          <p>
            The Client shall (i) pay to Nuvolum the amount of such invoice(s) in
            US dollars or (ii) pay to Nuvolum in US dollars the monthly fixed
            fee and the amount of any undisputed expenses. If there is a
            question or dispute with an invoice, the Client will provide to
            Nuvolum written notice of the Client’s objection to such invoice.
            This notice shall set forth in reasonable detail the reasons for the
            Client’s objection to the nature or amount of the invoice. This
            notice of dispute must be relayed in writing to Nuvolum within five
            (5) days after receipt of the invoice. If notification is not
            received within five (5) days of receipt of the invoice, the invoice
            conclusively shall be deemed correct and payment in full shall be
            made upon receipt of the invoice.
          </p>
          <p>
            In the event that Nuvolum brings a successful collection action
            against Client for any outstanding payment, Client shall be
            responsible for all associated fees and expenses, including but not
            limited to attorneys’ fees, court costs, mediation and arbitration
            fees, collection consultant commissions, as well as interest on all
            unpaid amounts and collection fees and expenses at 1.5% per month.
            Invoices not in dispute and outstanding beyond fifteen (15) days
            from the due date shall bear interest at 1.5% per month and be
            subject to a late fee of 5% of invoice amount. Invoices in dispute,
            where the Client has timely notified Nuvolum of the dispute, will
            not accrue interest or a late fee until the dispute is resolved.
          </p>

          <p className="bold">
            7.0 INTELLECTUAL PROPERTY WARRANTY AND INDEMNITY
          </p>
          <p>
            Nuvolum warrants that the services provided for the Client under
            this Agreement will not infringe any copyrights, trademarks,
            patents, trade secrets or other intellectual property rights or
            privacy or similar rights of any person or entity, provided that
            Client has not breached its warranty as set forth below.{" "}
          </p>
          <p>
            The Client warrants that all data and information provided to
            Nuvolum for purposes of performing the services under this
            Agreement, including but not limited to confidential and proprietary
            information, shall not infringe upon any copyrights, trademarks,
            patents, trade secrets or other intellectual property rights or
            privacy or similar rights of any person or entity, and that no such
            claims are threatened, asserted or pending. Each party shall
            indemnify and hold harmless the other and its directors, officers,
            partners, employees, representatives, agents and affiliates from any
            and all loss or liability for any and all third party claims, causes
            of action, suits, proceedings, losses, damages, demands, fees,
            expenses, fines, penalties, and costs (including, without
            limitation, reasonable attorneys’ fees, costs, and disbursements)
            arising from breach of the warranty provided hereunder.
          </p>
          <p>
            Nuvolum PR-Social Campaigns, also called Nuvolum Programs, are the
            Intellectual Property of, and are proprietary to, Nuvolum. If
            licensed, Client is only authorized to use said PR-Social Campaigns
            during the year for which they were licensed. Client is not
            permitted to duplicate or otherwise replicate and/or conduct
            PR-Social Campaigns that are in any way similar to Nuvolum’s
            Campaigns. Further, if Client chooses to pass or cancel a licensed
            Campaign, it is thereby forfeited by Client and cannot be
            rescheduled.
          </p>

          <p className="bold">8.0 LIMITATION OF LIABILITY/DISCLAIMER</p>
          <p>
            In no event shall Nuvolum be liable to Client or any third party for
            any indirect, incidental, special, or consequential damages arising
            out of this Agreement, including but not limited to damages for lost
            profits, lost opportunity, lost savings, or lost data, even if
            Nuvolum has been advised of the possibility of any such damages. In
            no event shall Nuvolum be liable for amounts greater than any fees
            paid by Client under this Agreement in any calendar year.
          </p>

          <p className="bold">9.0 CONFIDENTIAL AND PROPRIETARY INFORMATION</p>
          <p>
            Nuvolum agrees, during the term of this Agreement, not to disclose
            or use, other than for purposes of performing under this Agreement,
            any of Client’s confidential or proprietary information that has
            been identified in writing as such by Client. This obligation shall
            not apply to information already in Nuvolum’s possession not subject
            to this Agreement, information which becomes part of the public
            domain, information provided to Nuvolum by a third party who is not
            subject to a confidentiality restriction, and information which is
            disclosed in response to any judicial, governmental or regulatory
            request. Provided that all amounts owing hereunder have been paid,
            Client’s confidential and proprietary information shall be returned
            or destroyed within thirty (30) days of receipt by Nuvolum of
            Client’s written request.
          </p>
          <p>
            Client agrees, not to disclose or use, other than for purposes of
            performing under this Agreement, any of Nuvolum’s confidential or
            proprietary information. This includes, but is not limited to,
            Nuvolum’s: patient experience methodologies, marketing
            methodologies, pr-social campaigns and program processes, business
            practices, client lists, tool/service functionality and
            methodologies (including those of associated companies/partners that
            are provided by Nuvolum to Client), etc. This obligation shall not
            apply to information already part of the public domain, information
            provided to Client by a third party who is not subject to a
            confidentiality restriction, and information which is disclosed in
            response to any judicial, governmental or regulatory request.
          </p>
          <p>
            Neither Nuvolum, nor Client, shall publicly disclose the terms of
            this Agreement to any third party, unless required by law, nor shall
            the existence of said relationship between the parties be disclosed
            without the express written consent of the other party. This shall
            apply to both the corporate identities, as well as the individuals
            employed by, and/or engaged by, each party.
          </p>

          <p className="bold">10.0 TERMINATION OR SUSPENSION</p>
          <p>
            Either Party may terminate or suspend this Agreement at the end of
            any term by providing the other Party with at least ninety (90)
            days’ (“Notice Period”) prior written notice of its intention to
            terminate or suspend (“Notice”) and by paying to Nuvolum in
            immediately available funds an amount equal to the aggregate of all
            outstanding amounts owed Nuvolum. The Notice Period shall not begin
            until such time as the other Party receives written Notice. During
            the Notice Period Nuvolum will continue providing services in
            accordance with Attachment A. During the Notice Period Client is
            responsible for payment of the fixed fee and expenses in accordance
            with the terms of this Agreement. Notwithstanding the foregoing,
            Nuvolum may terminate or suspend this Agreement at any time for
            non-payment of any amounts owing hereunder, or if Nuvolum deems
            Client to be operating outside of the parameters necessary for the
            successful execution of this Agreement. If Nuvolum must terminate
            the Agreement under these conditions, the remaining balance of all
            monies owed to Nuvolum under the Agreement, shall be due and payable
            in full at the time of termination.
          </p>
          <p>
            Termination or suspension shall be effective at the end of the
            Notice Period. Nuvolum shall not be obligated to provide and/or
            return any of the materials furnished by or developed for the Client
            until the Client makes the full final payment.
          </p>

          <p className="bold">11.0 ENTIRE AGREEMENT</p>
          <p>
            This Agreement contains the entire understanding of the parties with
            respect to contracting for Nuvolum’s services. There are no
            promises, covenants, or undertakings other than those expressly set
            forth herein. This Agreement may not be modified, unless agreed to
            in writing by Jock Putney or Jeff Thompson and authorized
            representatives of the Client.
          </p>

          <p className="bold">12.0 SEVERABILITY</p>
          <p>
            In the event that at any future time it is determined by a court of
            competent jurisdiction that any provision or term herein is illegal,
            invalid, or unenforceable, the remaining provisions and terms of
            this Agreement shall not be affected thereby and the illegal,
            invalid or unenforceable term or provision shall be severed from the
            remainder of this Agreement. In the event of such severance, the
            remaining terms and provisions shall be binding and enforceable.
          </p>

          <p className="bold">13.0 ITEMS TO BE FURNISHED BY CLIENT</p>
          <p>
            Client understands and agrees that performance of this Agreement by
            Nuvolum is contingent upon Client furnishing to Nuvolum such
            materials, facilities, personnel or services as may be requested by
            Nuvolum from time to time.
          </p>

          <p className="bold">14.0 EMPLOYEE HIRING</p>
          <p>
            Nuvolum and Client agree that, during the term of this Agreement and
            for a period of one hundred eighty (180) days thereafter, neither
            party will directly or indirectly hire or engage as an independent
            contractor or any other capacity, any person who is or was an
            employee of the other whether still employed by the Client or
            Consultant, employed with a third party or self-employed, without
            the express written consent of the other party.
          </p>

          <p className="bold">15.0 GOVERNING LAW</p>
          <p>
            This Agreement shall be governed by the laws of the State of
            California, without regard for its conflicts of laws and principles.
            The Client consents to venue in and to the exclusive jurisdiction of
            the state and federal courts of the State of California, County of
            San Francisco for any dispute arising out of this Agreement.{" "}
          </p>

          <p className="bold">16.0 NOTICE</p>
          <p>
            Any notice required to be provided under this Agreement shall be
            sent to:
          </p>
          <p>
            Nuvolum Inc.
            <br />
            Attn: Legal Department
            <br />
            435 Pacific Ave, 4th Floor
            <br />
            San Francisco, CA 94133
          </p>

          <p className="bold">17.0 INDEPENDENT CONTRACTOR</p>
          <p>
            Nuvolum, in rendering services under this Agreement, is acting and
            shall act solely as an independent contractor and not as the agent
            of Client.
          </p>

          <p className="bold">18.0 NO ASSIGNMENT</p>
          <p>
            This Agreement shall not be assigned or transferred by either party
            without the prior written consent of the other party, except in the
            event of a merger, acquisition, or other change in control
            transaction in which the party’s business will be substantially
            continued.
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default PSATC
