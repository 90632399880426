import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Heading } from "../components/Hero"
import Seamless from "../components/Seamless"
import { Office, Phone, Email } from "../components/locations"
import { ContactForm } from "../components/Forms"

const ContactPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const locations = data.locations.nodes

  const {
    formHeading,
    heroSection: { content }
  } = post

  // const locale = Intl.DateTimeFormat().resolvedOptions().locale

  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <div className="contact-page">
        <div className="contact-page-hero-container">
          <Heading content={content} />
        </div>

        <Section className="contact-page__main-section">
          <Container>
            <div className="contact-page__columns">
              <div className="contact-page__offices">
                <div className="contact-page__offices-list">
                  {locations.map((loc, i) => (
                    <div className="footer__single-office" key={i}>
                      <Office
                        heading={loc.name}
                        streetNumber={loc.streetNumber}
                        streetName={loc.streetName}
                        state={loc.stateAbbr}
                        zip={loc.cityZip}
                        city={loc.cityName}
                        gmb={loc.googlePlaceLink}
                      />
                    </div>
                  ))}
                  <Phone phoneNumber={locations[0].phoneNumber} />
                  <Email email={locations[0].email} />
                </div>
              </div>
              <div>
                <MarkdownViewer markdown={formHeading} />
                <ContactForm classNames="mt-2" redirect="/thank-you/" />
                {/* <Seamless src="/marketingAnalysisForm.js" /> */}
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query contactPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          content
        }
        formHeading
      }
    }
    locations: allLocationsJson(sort: { fields: order }) {
      nodes {
        cityName
        cityZip
        name
        stateAbbr
        streetName
        streetNumber
        googlePlaceLink
        phoneNumber
        email
      }
    }
  }
`
