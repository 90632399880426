import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import NuvoImage from "../components/NuvoImage"
import { Container, Section } from "../components/Layouts"
import { MarketingAnalysisFormTwo } from "../components/Forms"
import Button from "../components/Buttons/Button"
import { LeftPopup } from "../components/Popups"

const singleMarkdownPage = ({ data, path, location }) => {
  const {
    metaDescription,
    metaTitle,
    heading,
    content,
    formOptions,
    heroImage
  } = data.allMarkdownPagesJson.nodes[0]

  return (
    <Layout path={path} className="markdown-page">
      <SEO title={metaTitle} description={metaDescription} />

      {formOptions?.hasPopupForm && (
        <LeftPopup pageUrl={location.pathname} startHidden />
      )}

      <Section>
        <Container>
          {heroImage && (
            <div className="mb-2">
              <NuvoImage cloudname="nuvolum" publicId={heroImage} />
            </div>
          )}
          <h1>{heading}</h1>
          {formOptions?.hasCTAForm && formOptions?.button?.href && (
            <Button
              rounded
              green
              className="mb-2"
              buttonText={formOptions.button.buttonText}
              href={formOptions.button.href}
              destination={formOptions.button.destination}
              appearance={formOptions.button.appearance}
            />
          )}
        </Container>
      </Section>

      {formOptions.hasCTATopForm && (
        <Section colorBack className="relative">
          <Container small>
            <MarketingAnalysisFormTwo
              id="cta-form"
              origin={location.href}
              phone="(415) 304-8425"
            />
          </Container>
        </Section>
      )}

      <Section>
        <Container>
          <MarkdownViewer markdown={content} />
        </Container>
      </Section>
      <Section colorBack className="relative">
        <Container small>
          <MarketingAnalysisFormTwo
            id="cta-form"
            origin={location.href}
            phone="(415) 304-8425"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default singleMarkdownPage

export const singleMarkdownPageQuery = graphql`
  query singleMarkdownPage($title: String!) {
    allMarkdownPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        heroImage
        heading
        content
        formOptions {
          hasPopupForm
          hasCTATopForm
          hasCTAForm
          button {
            buttonText
            href
            appearance
            destination
          }
        }
      }
    }
  }
`
