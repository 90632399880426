import React from "react"
import PropTypes from "prop-types"
import Layout from "components/layout"
import SEO from "components/seo"

import MarkdownViewer from "@components/MarkdownViewer"

import ImageMeta from "components/ImageMeta"

import ResponsivePlayer from "components/ResponsivePlayer.js"
import Seamless from "components/Seamless"

import { Link, graphql } from "gatsby"

const widont = text => text.replace(/([^\s])\s+([^\s]+)\s*$/, "$1\u00a0$2")

function SingleSalesLandingPage(props) {
  let page = props.data.allSalesLandingPagesJson.nodes[0]

  let multipleVideos

  if (page.wistias) {
    multipleVideos = page.wistias.map((video, i) => (
      <div key={video} className="columns is-mobile video-section">
        <div className="column is-2-mobile is-4-tablet"></div>
        <div className="column">
          <ResponsivePlayer
            url={video}
            style={{
              marginBottom: i === page.wistias.length - 1 ? "0" : "3rem"
            }}
          />
        </div>
        <div className="column is-2-mobile is-5-tablet"></div>
      </div>
    ))
  }

  return (
    <Layout>
      <SEO
        title={page.heading}
        description={page.metaDescription}
        robots="noindex"
      />

      <div className="single-sales-landing-page">
        <div className="columns is-mobile">
          <div className="column is-2-mobile is-4-tablet"></div>
          <div className="column">
            <h1>{page.heading}</h1>
          </div>
          <div className="column is-2-mobile is-4-tablet"></div>
        </div>

        {page.wistias ? (
          multipleVideos
        ) : (
          <div className="columns is-mobile video-section">
            <div className="column is-2-mobile is-4-tablet"></div>
            <div className="column">
              <ResponsivePlayer
                url={page.wistiaCode}
                style={{ marginBottom: "0" }}
              />
            </div>
            <div className="column is-2-mobile is-5-tablet"></div>
          </div>
        )}

        <div className="columns is-mobile">
          <div className="column is-2-mobile is-4-tablet"></div>
          <div className="column">
            <h2>{widont(page.headingUnderVideo)}</h2>
          </div>
          <div className="column is-2-mobile is-4-tablet"></div>
        </div>

        <div className="columns">
          <div className="column is-4"></div>
          <div className="column">
            <div style={{ margin: "0 0 48px 0" }}>
              <MarkdownViewer markdown={page.text} />
            </div>
            <Seamless src="/marketingAnalysisForm.js" />
          </div>
          <div className="column is-4"></div>
        </div>
      </div>
    </Layout>
  )
}

SingleSalesLandingPage.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query SalesLandingTemplate($title: String!) {
    allSalesLandingPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        wistiaCode
        wistias
        title
        text
        headingUnderVideo
        heading
      }
    }
  }
`

export default SingleSalesLandingPage
