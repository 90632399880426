import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import ImageMeta from "../components/ImageMeta"
import Button from "../components/Buttons/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar"
import Slider from "react-slick"
import { MarketingAnalysisFormTwo } from "../components/Forms"
import NuvoImage from "../components/NuvoImage"
import { LeftPopup } from "../components/Popups"
import TheaterVideo from "../components/Theater/TheaterVideo"

const OrthodonticsMarketingPage = ({ data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { href, pathname } = location

  const stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(<FontAwesomeIcon icon={faStar} key={i} />)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true
  }

  const {
    heroSection,
    customWebsites,
    // chooseSection,
    strategiesSection,
    cardsSection,
    ideasSection,
    marketingSection,
    schema
  } = post

  const anchorLink = "#cta-form/"

  return (
    <Layout path={pathname} className="marketing-blog">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={schema ?? null}
      />

      {/* <LeftPopup pageUrl={pathname} startHidden /> */}

      <div className="sizzle-container">
        <TheaterVideo
          videoUrl="https://wuwta.wistia.com/medias/jiw50e99p1"
          language="en"
          overVideo
          buttonClass="contained">
          <NuvoImage
            cloudName="nuvolum"
            publicId={heroSection.image}
            responsive
            width="auto"
            useAR
          />
        </TheaterVideo>
      </div>

      <div className="ortho-marketing-page">
        <Section className="hero__heading">
          <Container large>
            <MarkdownViewer markdown={heroSection.content} />
            <div className="columns is-centered form">
              <Button
                rounded
                green
                anchor
                href={anchorLink}
                buttonText={heroSection.buttonText}
              />
            </div>
          </Container>
        </Section>

        <Section className="ortho-marketing-page color-back">
          <Container large>
            <div className="has-text-centered">
              <MarkdownViewer markdown={customWebsites.content} />
            </div>
            {customWebsites.websites[0] && (
              <Section>
                <div className="columns">
                  {customWebsites.websites.map((website, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="column">
                          {website.href ? (
                            <a href={website.href} target="_blank">
                              <ImageMeta
                                cloudName="nuvolum"
                                publicId={website.image}
                                responsive
                                width="auto"
                              />
                            </a>
                          ) : (
                            <ImageMeta
                              cloudName="nuvolum"
                              publicId={website.image}
                              responsive
                              width="auto"
                            />
                          )}
                          {website.name && (
                            <h5 style={{ marginTop: 15 }}>{website.name}</h5>
                          )}
                          {website.href && (
                            <div className="custom-website-button">
                              <Button
                                buttonText="View Site"
                                href={website.href}
                                external
                              />
                            </div>
                          )}
                        </div>
                        {i < 2 && <div className="column is-1">&nbsp;</div>}
                      </React.Fragment>
                    )
                  })}
                </div>
              </Section>
            )}
            <div
              className={`columns is-centered form ${
                !customWebsites.websites.length ? "mt-3" : ""
              }`}>
              {/* <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {customWebsites.buttonText}{" "}
              </button> */}
              <Button
                rounded
                green
                anchor
                href={anchorLink}
                buttonText={customWebsites.buttonText}
              />
            </div>
          </Container>
        </Section>

        {/* <Section>
          <Container large>
            <MarkdownViewer markdown={chooseSection.content} />
            <Section>
              <div className="columns is-centered">
                {chooseSection.numbers.map((number, i) => {
                  return (
                    <>
                      <div className="column circle-container" key={i}>
                        <div className="circle">
                          <h5>{number.top}</h5>
                          <br />
                          <p>{number.bottom}</p>
                        </div>
                      </div>
                      {i < 2 && <div className="column is-1">&nbsp;</div>}
                    </>
                  )
                })}
              </div>
            </Section>
            <div className="columns is-centered form">
              <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {chooseSection.buttonText}{" "}
              </button>
            </div>
          </Container>
        </Section> */}

        <Section>
          <Container large>
            <MarkdownViewer markdown={strategiesSection.content} />
            <Section>
              <div>
                <ol className="strategies-section">
                  {strategiesSection.strategies.map((strategy, i) => {
                    return (
                      <li key={i}>
                        <h4>{strategy.title}</h4>
                        <p>{strategy.text}</p>
                      </li>
                    )
                  })}
                </ol>
              </div>
            </Section>
            <div className="columns is-centered">
              {/* <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {strategiesSection.buttonText}{" "}
              </button> */}
              <Button
                rounded
                green
                anchor
                href={anchorLink}
                buttonText={strategiesSection.buttonText}
              />
            </div>
          </Container>
        </Section>

        <Section className="color-back">
          <Container large>
            <MarkdownViewer markdown={cardsSection.content} />
            {/* <Section>
              <TheaterVideo videoUrl={cardsSection.youtube}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={cardsSection.image}
                  responsive
                  width="auto"
                />
              </TheaterVideo>
            </Section> */}
          </Container>
          {cardsSection.cards[0] && (
            <Section>
              <Container full>
                {cardsSection.cards.length > 3 ? (
                  <Slider {...settings}>
                    {cardsSection.cards.map((card, i) => {
                      return (
                        <div className="card card-slider-height" key={i}>
                          <div className="card-content">
                            <div className="media">
                              <div className="media-left">
                                <ImageMeta
                                  cloudName="nuvolum"
                                  publicId={card.image}
                                  responsive
                                  width="auto"
                                />
                              </div>
                              <div className="media-content">
                                <p className="title">{card.name}</p>
                                <p className="subtitle">{card.practice}</p>
                              </div>
                            </div>
                            <div className="card-rating">
                              <div className="stars">{stars}</div>
                              <p>{card.title}</p>
                            </div>

                            <div className="content">
                              <span>&ldquo;</span>
                              <p>{card.text}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                ) : (
                  <div className="columns is-desktop">
                    {cardsSection.cards.map((card, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="card column">
                            <div className="card-content">
                              <div className="media">
                                <div className="media-left">
                                  <ImageMeta
                                    cloudName="nuvolum"
                                    publicId={card.image}
                                    responsive
                                    width="auto"
                                  />{" "}
                                </div>
                                <div className="media-content">
                                  <p className="title">{card.name}</p>
                                  <p className="subtitle">{card.practice}</p>
                                </div>
                              </div>
                              <div className="card-rating">
                                <div className="stars">{stars}</div>
                                <p>{card.title}</p>
                              </div>

                              <div className="content">
                                <span>&ldquo;</span>
                                <p>{card.text}</p>
                              </div>
                            </div>
                          </div>
                          {i < 2 && <div className="column is-1">&nbsp;</div>}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              </Container>
            </Section>
          )}
          <Container>
            <div className="columns is-centered form">
              {/* <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {cardsSection.buttonText}{" "}
              </button> */}
              <Button
                anchor
                rounded
                green
                href={anchorLink}
                buttonText={cardsSection.buttonText}
              />
            </div>
          </Container>
        </Section>

        <Section>
          <Container large>
            <MarkdownViewer markdown={ideasSection.content} />
          </Container>
        </Section>

        <Section className="color-back work-together">
          <Container large>
            <div className="has-text-centered">
              <MarkdownViewer markdown={marketingSection.content} />
            </div>
            <Section>
              <div className="columns marketing-section">
                {marketingSection.steps.map((step, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="column marketing-section__steps">
                        <div className="circle">
                          <h6 id={`num-${i + 1}`}>{i + 1}</h6>
                        </div>
                        <h5>{step.top}</h5>
                        <p>{step.bottom}</p>
                      </div>
                      {i < 2 && (
                        <>
                          <div className="arrow is-hidden-mobile">
                            <span>&#10230;</span>
                          </div>
                          <div className="column is-1 is-hidden-tablet">
                            &nbsp;
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </Section>
            <div className="columns is-centered form">
              {/* <button className="standard-button form__trigger">
                <Arrow rightSideArrow={false} />
                {marketingSection.buttonText}
              </button> */}
              <Button
                rounded
                buttonText={marketingSection.buttonText}
                href="/contact/"
              />
            </div>
          </Container>
        </Section>

        <Section colorBack className="relative">
          <Container small>
            <MarketingAnalysisFormTwo
              id="cta-form"
              origin={href}
              phone="(415) 304-8425"
            />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default OrthodonticsMarketingPage

export const pageQuery = graphql`
  query orthodonticsMarketingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schema
        metaTitle
        metaDescription
        heroSection {
          content
          image
          buttonText
        }
        customWebsites {
          content
          websites {
            name
            image
            href
          }
          buttonText
        }
        strategiesSection {
          content
          strategies {
            title
            text
          }
          buttonText
        }
        cardsSection {
          content
          youtube
          image
          cards {
            name
            image
            practice
            title
            text
          }
          buttonText
        }
        ideasSection {
          content
        }
        marketingSection {
          content
          steps {
            top
            bottom
          }
          buttonText
        }
      }
    }
    locations: allLocationsJson(sort: { fields: order }) {
      nodes {
        cityName
        cityZip
        name
        stateAbbr
        streetName
        streetNumber
        googlePlaceLink
        phoneNumber
        email
      }
    }
  }
`
