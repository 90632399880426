import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import ImageMeta from "../components/ImageMeta"
import Seamless from "../components/SeamlessEmbed"
import Youtube from "../components/Youtube"

const SingleEmailBlast = ({ data, path, location }) => {
  const post = data.allEmailBlastsJson.nodes[0]
  const { heading, blurb, bodySections, form } = post
  return (
    <Layout className="email-blast" path={path}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        robots="noindex"
      />

      <Section>
        <Container>
          <div className="email-blast__heading">
            <h1>{heading}</h1>
            {blurb && <MarkdownViewer markdown={blurb} />}
          </div>
        </Container>
      </Section>

      <div className="email-blast__assessment">
        <div className="email-blast__assessment-content">
          <Container className="px-0">
            {bodySections.map((section, i) => {
              if (section.type === "textSection") {
                return (
                  <Section key={i} smallSpacing zeroTop={i === 0}>
                    <MarkdownViewer markdown={section.text} />
                  </Section>
                )
              }

              if (section.type === "imageSection") {
                return (
                  <Section key={i} smallSpacing>
                    <ImageMeta
                      className="email-blast__content-image"
                      publicId={section.image}
                    />
                  </Section>
                )
              }

              if (section.type === "youtubeIframe") {
                return (
                  <Section key={i} smallSpacing>
                    <Youtube code={section.youtubeCode} />
                  </Section>
                )
              }
              return null
            })}
          </Container>
        </div>
        <div className="email-blast__assessment-form">
          <Container className="px-0">
            <MarkdownViewer markdown={form.heading} />
            <div className="email-blast__assessment-iframe">
              <Seamless src={form.iframeSrc} />
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default SingleEmailBlast

export const singleEmailBlastQuery = graphql`
  query singleCampaign($title: String!) {
    allEmailBlastsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        heading
        bodySections {
          type
          text
          image
          youtubeCode
        }
        form {
          heading
          iframeSrc
        }
      }
    }
  }
`
