import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Heading } from "../components/Hero"
import Layout from "../components/layout"
import { Container, Section } from "../components/Layouts"
import SEO from "../components/seo"
import Portfolio from "../components/Portfolio/Portfolio"
import Video from "../components/Video/Video"
import Sizzle from "../videos/sizzle-home.mp4"
import MarkdownViewer from "@components/MarkdownViewer"
import { Parallax } from "react-parallax"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons/faArrowUpRight"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import TheaterVideo from "../components/Theater/TheaterVideo"
import NuvoImage from "../components/NuvoImage"
import Slider from "react-slick"
import { InView } from "react-intersection-observer"

const Homepage = ({ data, pageContext, location, path }) => {
  const [currClientSlide, setCurrClientSlide] = useState(0)
  const [currStrategySlide, setCurrStrategySlide] = useState(0)

  let clientsSliderRef = useRef(null)
  let strategiesSliderRef = useRef(null)

  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext
  const {
    heroSection: { heading, content, button },
    cta,
    marketingStrategies,
    satisfiedClients
  } = post

  const totalClientsCount = satisfiedClients.clientsList.length
  const totalStrategiesCount = marketingStrategies.strategiesList.length

  const clientsSliderSettings = {
    className: "center",
    infinite: false,
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    dots: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerPadding: "30px",
          beforeChange: index => setCurrClientSlide(index),
          afterChange: index => setCurrClientSlide(index)
        }
      }
    ]
  }

  const strategiesSliderSettings = {
    className: "center",
    infinite: false,
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    dots: false,
    centerMode: true,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          beforeChange: index => setCurrStrategySlide(index),
          afterChange: index => setCurrStrategySlide(index)
        }
      }
    ]
  }

  const getSliderArrows = (currentSlideIdx, totalSlides, sliderRef) => {
    return (
      <div
        className={`slider-arrow-container mt-1 ${
          currentSlideIdx === 0
            ? "flex-end"
            : currentSlideIdx === totalSlides.length - 1
            ? "flex-start"
            : ""
        }`}>
        <button
          aria-label="previous carousel slide"
          className={`reset slider-arrow ${
            currentSlideIdx === 0 ? "hide-prev" : ""
          }`}
          onClick={() =>
            sliderRef === "clientsSliderRef"
              ? clientsSliderRef.slickPrev()
              : strategiesSliderRef.slickPrev()
          }>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          aria-label="next carousel slide"
          className={`reset slider-arrow ${
            currentSlideIdx === totalSlides - 1 ? "hide-next" : ""
          }`}
          onClick={() =>
            sliderRef === "clientsSliderRef"
              ? clientsSliderRef.slickNext()
              : strategiesSliderRef.slickNext()
          }>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    )
  }
  return (
    <Layout
      className={"main-homepage"}
      language={language}
      isNavOverHero={true}
      path={path}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schema ?? null}
        pathname={location.pathname}
        preload={
          <link
            rel="preload"
            as="image"
            href="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_auto/v1/NUVO/V2/homepage-hero-mobile-1"></link>
        }
      />
      <Heading markdown={heading} />

      <Parallax
        renderLayer={percentage => (
          <div
            className="homepage__sizzle"
            style={{
              width: `${10 * percentage + 85}%`
            }}>
            <Video src={Sizzle} />{" "}
          </div>
        )}></Parallax>

      <Section>
        <Container full>
          <MarkdownViewer markdown={content} />
          <Link
            to={button.href}
            title={button.buttonText}
            className="standard-button homepage__button mt-2">
            <span>{button.buttonText}</span>
            <FontAwesomeIcon icon={faArrowUpRight} />
          </Link>
        </Container>
      </Section>

      {/* <Section zeroTop>
        <Portfolio listColor="white" />
      </Section> */}

      <Section zeroBottom>
        <Container full>
          <MarkdownViewer
            markdown={satisfiedClients.heading}
            className="homepage__clients-heading mb-2"
          />
          {/**Desktop View */}
          <div className="homepage__clients is-hidden-touch">
            {satisfiedClients.clientsList.map(
              ({ youtube, image, title, subtitle }, i) => (
                <div className="homepage__clients-video">
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                    language="en"
                    buttonClass="contained homepage__clients-video"
                    iconOnly
                    overVideo>
                    <NuvoImage
                      cloudName="nuvolum"
                      publicId={image}
                      responsive
                    />
                    <div className="gradient-overlay"></div>
                    <div className="homepage__clients-content">
                      <MarkdownViewer
                        markdown={title}
                        className="homepage__clients-img-title"
                      />
                      <MarkdownViewer
                        markdown={subtitle}
                        className="homepage__clients-img-subtitle"
                      />
                    </div>
                  </TheaterVideo>
                </div>
              )
            )}
          </div>
        </Container>
      </Section>

      {/**Mobile View */}
      <Section>
        <div>
          <div className="is-hidden-desktop">
            <Slider
              ref={slider => {
                clientsSliderRef = slider
              }}
              {...clientsSliderSettings}>
              {satisfiedClients.clientsList.map(
                ({ youtube, image, title, subtitle }, i) => (
                  <div className="homepage__clients-video">
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                      language="en"
                      buttonClass="contained homepage__clients-video"
                      iconOnly
                      overVideo>
                      <NuvoImage
                        cloudName="nuvolum"
                        publicId={image}
                        responsive
                        className="homepage__clients-image"
                      />
                      <div className="gradient-overlay"></div>
                      <div className="homepage__clients-content">
                        <MarkdownViewer
                          markdown={title}
                          className="homepage__clients-img-title"
                        />
                        <MarkdownViewer
                          markdown={subtitle}
                          className="homepage__clients-img-subtitle"
                        />
                      </div>
                    </TheaterVideo>
                  </div>
                )
              )}
            </Slider>
            {getSliderArrows(
              currClientSlide,
              totalClientsCount,
              "clientsSliderRef"
            )}
          </div>
        </div>
      </Section>

      {/**Desktop View */}
      <Section zeroTop zeroBottom className="is-hidden-touch">
        <Container full>
          <div className="strategies-list">
            <div className="strategies-list__images relative">
              <MarkdownViewer
                markdown={marketingStrategies.heading}
                className="homepage__ms-heading"
              />
              <NuvoImage
                cloudName="nuvolum"
                className="homepage__ms-image"
                publicId={marketingStrategies.image}
                responsive
                width="auto"
              />
            </div>
            <div className="strategies-list__content">
              {marketingStrategies.strategiesList.map((strategy, i) => (
                <InView as="div" threshold={0.7} key={i}>
                  <div className="strategy-card__content">
                    <div
                      className={`strategy-card__content--container ${
                        i + 1 === marketingStrategies.strategiesList.map
                          ? "last"
                          : ""
                      }`}>
                      <div>
                        <MarkdownViewer
                          markdown={strategy.name}
                          className="homepage__ms-subheading"
                        />
                        <MarkdownViewer markdown={strategy.content} />
                        <div className="homepage__ms-point-section">
                          {strategy.points.map(point => (
                            <div className="homepage__ms-point">
                              <FontAwesomeIcon icon={faPlus} />
                              <MarkdownViewer
                                key={point.text}
                                markdown={point.text}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </InView>
              ))}
            </div>
          </div>
        </Container>
      </Section>

      {/** MOBILE VIEW */}
      <Section className="is-hidden-desktop">
        <div>
          <div className="homepage__ms-heading-sctn">
            <MarkdownViewer
              markdown={marketingStrategies.heading}
              className="homepage__ms-heading"
            />
          </div>
          <div>
            <Slider
              ref={slider => {
                strategiesSliderRef = slider
              }}
              {...strategiesSliderSettings}>
              {marketingStrategies.strategiesList.map((strategy, index) => (
                <div key={index} className="strategies-card">
                  <MarkdownViewer
                    markdown={strategy.name}
                    className="homepage__ms-subheading"
                  />
                  <MarkdownViewer markdown={strategy.content} />
                  <div className="strategies-card-points">
                    {strategy.points.map(point => (
                      <div className="homepage__ms-point">
                        <FontAwesomeIcon icon={faPlus} />
                        <MarkdownViewer
                          key={point.text}
                          markdown={point.text}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
            {getSliderArrows(
              currStrategySlide,
              totalStrategiesCount,
              "strategiesSliderRef"
            )}
          </div>
        </div>
      </Section>

      <Section zeroTop className="homepage__cta">
        <Container full>
          <div className="homepage__cta-sctn">
            <div className="homepage__cta-content">
              <MarkdownViewer markdown={`### ${cta.heading}`} />
              <div className="button-group">
                {cta.buttons.map(({ button }, i) => (
                  <Link
                    key={button.href}
                    to={button.href}
                    title={button.buttonText}
                    className="standard-button homepage__button">
                    <span>{button.buttonText}</span>
                    <FontAwesomeIcon icon={faArrowUpRight} />
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <NuvoImage
                cloudName="nuvolum"
                publicId={cta.image}
                responsive
                width="auto"
              />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  path: PropTypes.string,
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schema
        metaTitle
        metaDescription
        seoHeading
        heroSection {
          heading
          content
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        marketingStrategies {
          heading
          image
          strategiesList {
            name
            content
            points {
              text
            }
          }
        }
        satisfiedClients {
          heading
          clientsList {
            youtube
            image
            title
            subtitle
          }
        }
        cta {
          heading
          image
          buttons {
            button {
              href
              appearance
              buttonText
              destination
            }
          }
        }
      }
    }
  }
`

export default Homepage
