import React from "react"
import { graphql, Link } from "gatsby"
import MarkdownViewer from "@components/MarkdownViewer"
import Layout from "components/layout"
import SEO from "components/seo"
import ImageMeta from "components/ImageMeta"
import { Section, Container } from "../components/Layouts"

function ThankYouPage({ data }) {
  const page = data.allUniquePagesJson.edges[0].node

  const styles = {
    height: "45vh"
  }

  return (
    <Layout language="en">
      <SEO title={page.metaTitle} description={page.metaDescription} />

      <Section className="thank-you-page">
        <Container>
          <div style={styles}>
            <Link
              className="back-link mb-2"
              to="/"
              style={{ display: "block" }}>
              BACK
            </Link>

            <h1>{page.heading}</h1>
            <MarkdownViewer markdown={page.body} />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default ThankYouPage

export const pageQuery = graphql`
  query thankyouQuery {
    allUniquePagesJson(filter: { title: { eq: "/thank-you/" } }) {
      edges {
        node {
          heading
          metaDescription
          metaTitle
          name
          body
          title
        }
      }
    }
  }
`
