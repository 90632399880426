import React, { useState, useEffect } from "react"
import useWindowDimensions from "../hooks/useWindowDimensions"
import Button from "components/Buttons/Button"
import Layout from "components/layout"
import SEO from "components/seo"
import MarkdownViewer from "@components/MarkdownViewer"
import { graphql } from "gatsby"

function SingleJobPage(props) {
  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const iframe = document.getElementById("jazz-iframe")

    if (width <= 767 && !isMobile) {
      setIsMobile(true)

      iframe.height = "650"
    }

    if (width >= 768 && isMobile) {
      setIsMobile(false)
      iframe.height = "2120"
    }
  }, [width])

  let page = props.data.allJobsJson.edges[0].node
  const useJazzHr = page.jazzHR?.useJazzHR

  return (
    <Layout language="en" className="single-job">
      <SEO title={page.metaTitle} description={page.metaDescription} />
      <div className="job-page">
        <div className="section-wrapper columns">
          <div className="column is-3"></div>
          <div className="column">
            {!useJazzHr && (
              <>
                <h2>{page.heading}</h2>

                <div className="columns">
                  <div className="column is-3"></div>
                  <div className="column">
                    <MarkdownViewer markdown={page.body} />
                    <div className="button-wrapper">
                      <Button
                        href={page.button.href}
                        buttonText={page.button.buttonText}
                        className="blue"
                        external
                      />
                    </div>
                  </div>
                  <div className="column is-3"></div>
                </div>
              </>
            )}

            {useJazzHr && (
              <div className="jazz-iframe">
                <MarkdownViewer markdown={page.jazzHR.iframe} />
              </div>
            )}
          </div>
          <div className="column is-3"></div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleJobPage

export const pageQuery = graphql`
  query jobQuery($title: String!) {
    allJobsJson(filter: { title: { eq: $title } }) {
      edges {
        node {
          metaDescription
          metaTitle
          heading
          body
          button {
            buttonText
            href
            style
            destination
          }
          jazzHR {
            useJazzHR
            iframe
          }
        }
      }
    }
  }
`
